<template>
  <b-row v-if="loaded" class="h-100">
    <b-col class="h-100 d-flex align-items-center justify-content-center">
      <h1 style="margin-left: 15%;">
        <span style="font-size: 180px;">{{ alertsummary.deviceCount }}</span> Devices
      </h1>
    </b-col>
    <b-col class="h-100 d-flex align-items-center justify-content-center">
      <div style="margin-right: 15%;">
        <h1 style="color: red; margin-bottom: 20%;">{{ alertsummary.criticalCount }} Criticals</h1>
        <h1 style="color: orange; margin-bottom: 20%;">{{ alertsummary.warningCount }} Warnings</h1>
        <h1 style="color: purple; margin-bottom: 0;">{{ alertsummary.noticeCount }} Notices</h1>
      </div>
    </b-col>
  </b-row>
  <SpinnerCmpt v-else></SpinnerCmpt>
</template>

<script>
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';

export default {
  components: {
    SpinnerCmpt,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      alertsummary: {},
      loaded: false,
    };
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
          },
        })
        .then((response) => { this.alertsummary = response.data; })
        .finally(() => { this.loaded = true; });
    },
  },
};
</script>
