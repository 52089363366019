<template>
  <b-card class="mx-n2">
    <h5>xTends</h5>
    <b-row v-if="hasData" class="mx-0-5 mb-1" align-h="between">
      <b-col cols="12" md="6">
        <ShowEntries>
          <template #input>
            <b-form-select
              v-model="perPage"
              :options="options"
              size="sm"
            ></b-form-select>
          </template>
        </ShowEntries>
      </b-col>
      <b-col cols="12" md="6">
        <SearchBar>
          <template #input>
            <b-form-input v-model="filter" type="search" placeholder="Search" />
          </template>
        </SearchBar>
      </b-col>
    </b-row>
    <div v-if="hasData">
      <b-row>
        <b-col cols="12">
          <b-table
            striped
            hover
            responsive
            class="table-with-actions-min-height"
            primary-key="id"
            :per-page="perPage"
            :current-page="currentPage"
            :items="xTends"
            :fields="fields"
            :filter="filter"
            @filtered="onFiltered"
          >
            <template #cell(deviceName)="row">
              <b-button variant="flat-primary" @click="xTendLink(row.item.id)">{{ row.value }}</b-button>
            </template>
            <template #cell(alertCount)="row">
              <AppAlertRadialChart
                :url="alertRadialChartUrl(row.item.id)"
                @alertCount="handleAlertCount"
                :appId="row.item.id"
                :timeRange="timeRange"
              ></AppAlertRadialChart>
            </template>
            <template #cell(actions)="row">
              <XtendActions
                :deleteUrl="getDeleteUrl(row.item.id)"
                :xtendId="row.item.id"
                @deleted="httpGet"
                class="float-right"
              ></XtendActions>
            </template>
          </b-table>
        </b-col>
        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="paginationTotalRows"
            :per-page="perPage"
            align="right"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-2 mx-1"
          />
        </b-col>
      </b-row>
    </div>
    <div v-else-if="loaded">{{ $t("No xTend Device(s)") }}</div>
    <SpinnerCmpt v-else></SpinnerCmpt>
  </b-card>
</template>

<script>
import SearchBar from '@/xvisor/components/SearchBar.vue';
import AppAlertRadialChart from '@/xvisor/components/app/AppAlertRadialChart.vue';
import ShowEntries from '@/xvisor/components/ShowEntries.vue';
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import XtendActions from '@/xvisor/components/xtend/XtendActions.vue';

export default {
  components: {
    SearchBar,
    AppAlertRadialChart,
    ShowEntries,
    SpinnerCmpt,
    XtendActions,
  },
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      xTends: [],
      perPage: 5,
      currentPage: 1,
      filter: null,
      filteredLength: null,
      fields: [
        { key: 'deviceName', label: 'Device Name', thStyle: { width: '25%' } },
        { key: 'ip', label: 'IP', thStyle: { width: '25%' } },
        { key: 'alertCount', label: 'Alert Stats', thStyle: { width: '20%' } },
        { key: 'actions', label: '', thStyle: { width: '30%' } },
      ],
      options: [
        { value: 5, text: '5' },
        { value: 10, text: '10' },
        { value: 15, text: '15' },
      ],
      /** The time range for all components on the page. */
      loaded: false,
    };
  },
  computed: {
    hasData() {
      return this.loaded && this.xTends && this.xTends.length > 0;
    },
    paginationTotalRows() {
      if (this.filter) return this.filteredLength;
      return this.xTends.length;
    },
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    getDeleteUrl(id) {
      return `/xtends/${id}`;
    },
    xTendLink(id) {
      this.$router.push({ name: 'xtend', params: { id } });
    },
    alertRadialChartUrl(xtendId) {
      return `/xtends/${xtendId}/alerts/alertseverity.json`;
    },
    httpGet() {
      this.loaded = false;
      this.$http
        .get('/xtends/list.json', {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
          },
        })
        .then((response) => { this.xTends = response.data; })
        .finally(() => { this.loaded = true; });
    },
    onFiltered(filteredItems) {
      this.filteredLength = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
