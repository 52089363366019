<template>
  <div>
    <div v-show="hasData">
      <b-row>
        <b-col align="center">
          <CsvDownload
            v-if="flowData && flowData.links.length > 0"
            :fileName="`${elementId}-${timeRange.end.toISOString()}`"
            :dataRows="csvData"
            class="mb-n3 float-right"
          ></CsvDownload>
        </b-col>
      </b-row>
      <b-row>
        <b-col align="center">
          <div :id="elementId"></div>
        </b-col>
      </b-row>
    </div>
    <div v-if="loaded && !hasData"></div>
    <SpinnerCmpt v-else-if="!loaded"></SpinnerCmpt>
  </div>
</template>

<script>
import moment from 'moment';
import * as d3 from 'd3';
import $ from 'jquery';

import CsvDownload from '@/xvisor/components/CsvDownload.vue';
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';
import momentTimeFormat from '@/xvisor/constants/momentTimeFormat';
import popover from '@/xvisor/utilities/popover';
import readableBytes from '@/xvisor/utilities/readableBytes';

const TOP_ARC_ID = 'top-arc';

const MARGIN = {
  top: 10,
  right: 20,
  bottom: 10,
  left: 20,
};

const OUTER_TO_INNER_RADIUS_RADIO = 0.9;

// The additional scaling factor applied to the outer radius for the arc. This is used for the top arc.
const ARC_OUTER_RADIUS_SCALE = 0.1;

// The total amount of space allocated for padding between the arcs. The padding between two arcs is derived from
// dividing this by the number of arcs.
const TOTAL_ARC_PADDING = (Math.PI * 2) / 5;
const GRAPH_CLASS = 'flow-chord-graph';
const ARC_CLASS = 'arc';
const CHORD_CLASS = 'chord';
const EVENT_CLASS = 'event';

const ARROW_SYMBOL = '\u2192';
const DOUBLE_ARROW_SYMBOL = '\u2194';

// The color scale for each node based on its name.
const ARC_COLOR = d3.scaleOrdinal().range([
  colorPaletteShade.orange8,
  colorPaletteShade.purple8,
  colorPaletteShade.pink8,
  colorPaletteShade.yellow8,
  colorPaletteShade.green8,
  colorPaletteShade.blue8,
  colorPaletteShade.indigo8,
  colorPaletteShade.teal8,
  colorPaletteShade.gray8,
  colorPaletteShade.white,
]);

// The color scale for each link based on its protocol.
const CHORD_COLOR = d3.scaleOrdinal().range([
  colorPaletteShade.blue9,
  colorPaletteShade.blue8,
  colorPaletteShade.blue7,
  colorPaletteShade.blue6,
  colorPaletteShade.blue5,
  colorPaletteShade.blue4,
  colorPaletteShade.blue3,
  colorPaletteShade.blue2,
  colorPaletteShade.blue1,
]);

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    dimensions: {
      type: Number,
      default: 300,
    },
    timeRange: {
      type: Object,
      required: true,
    },
    elementId: {
      type: String,
      required: true,
    },
  },
  components: {
    CsvDownload,
    SpinnerCmpt,
  },
  data() {
    return {
      flowData: null,
      svgContainer: null,
      graphGroup: null,
      arc: null,
      chord: null,
      active: false,
      loaded: false,
    };
  },
  mounted() {
    this.httpGet();
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
    zoneId() {
      this.httpGet();
    },
  },
  computed: {
    hasData() {
      return this.loaded && this.flowData && this.flowData.links.length > 0;
    },
    zoneId() {
      return this.$store.state.zoneId;
    },
    mapIdToName() {
      if (this.flowData) {
        const nodesMap = {};
        nodesMap[TOP_ARC_ID] = this.flowData.cmptNodes[0].name;
        this.flowData.appUserNodes.forEach((node) => {
          nodesMap[node.id] = node.name;
        });
        return nodesMap;
      }
      return {};
    },
    csvData() {
      if (!this.flowData || this.flowData.links.length === 0) return [];
      return this.flowData.links.map((link) => ({
        'Component Name': this.mapIdToName[TOP_ARC_ID],
        'User/Device Name': this.mapIdToName[link.userId],
        Port: link.port,
        Protocol: link.proto,
        'Bytes Cmpt To User': link.cmptToUserBytes,
        'Bytes User To Cmpt': link.userToCmptBytes,
        'Packets Cmpt To User': link.cmptToUserPkts,
        'Packets User To Cmpt': link.userToCmptPkts,
      }));
    },
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
            zoneId: this.zoneId,
          },
        })
        .then((response) => {
          if (this.svgContainer) this.svgContainer.remove();
          this.flowData = response.data;
          this.$emit('flow-no-data', (this.flowData !== null && this.flowData.cmptNodes.length === 0));
          this.initialize();
          this.draw();
        })
        .finally(() => { this.loaded = true; });
    },
    initialize() {
      popover.registerPopover($(`#${this.elementId}`));
      this.svgContainer = d3.select(`#${this.elementId}`).append('svg')
        .classed(GRAPH_CLASS, true)
        .attr('width', 0)
        .attr('height', 0);
      const svgGroup = this.svgContainer
        .append('g')
        .attr('transform', `translate(${MARGIN.left}, ${MARGIN.top})`);
      // The group element to translate the graph into view.
      this.graphGroup = svgGroup
        .append('g');
      this.arc = this.graphGroup
        .append('g')
        .selectAll(`.${ARC_CLASS}`);
      const chordGroup = this.graphGroup
        .append('g');
      this.chord = chordGroup
        .selectAll(`.${CHORD_CLASS}`);
    },
    draw() {
      const layout = this.chordGraphLayout(this.flowData || []);
      const { mapIdToName } = this;
      this.arc = this.arc.data(layout.arcs, this.arcKey);
      this.arc.exit().remove();
      this.arc = this
        .arc
        .enter()
        .append('path')
        .classed(ARC_CLASS, true)
        .attr('fill', (d) => ARC_COLOR(d.id))
        .attr('stroke', (d) => d.color);

      this.chord = this.chord.data(layout.chords, this.chordFlowKey);
      this.chord.exit().remove();
      this.chord = this
        .chord
        .enter()
        .append('path')
        .classed(CHORD_CLASS, true)
        .attr('fill', (flow) => CHORD_COLOR(flow.protocol))
        .attr('stroke', (flow) => flow.color);

      const width = this.dimensions;
      const height = this.dimensions;
      const outerRadius = (height - (MARGIN.top + MARGIN.bottom)) / (2 + ARC_OUTER_RADIUS_SCALE);
      const innerRadius = outerRadius * OUTER_TO_INNER_RADIUS_RADIO;
      this
        .svgContainer
        .attr('width', width + MARGIN.left + MARGIN.right)
        .attr('height', height + MARGIN.top + MARGIN.bottom);

      this
        .graphGroup
        .attr(
          'transform',
          `translate(${Math.max(outerRadius, Math.floor(width / 2))}, ${outerRadius * (1 + ARC_OUTER_RADIUS_SCALE)})`,
        );

      const arcSvg = d3.arc()
        .innerRadius(innerRadius)
        .outerRadius((d) => outerRadius * (1 + (d.id === TOP_ARC_ID ? ARC_OUTER_RADIUS_SCALE : 0)));

      this.arc = this.arc.attr('d', arcSvg);
      this.chord = this.chord.attr('d', d3.ribbon().radius(innerRadius));

      this
        .arc
        .attr('data-html', true)
        .attr('data-toggle', 'popover')
        .attr('data-title', (d) => mapIdToName[d.id])
        .attr('data-content', (node) => {
          const vlanIds = node.vlanIds ? Array.from(node.vlanIds).filter((id) => id) : undefined;
          const tooltipBody = vlanIds && vlanIds.length !== 0
            ? [`<div>VLAN ID(s): ${vlanIds.join(', ')}</div>`, readableBytes(node.bytes)].join('')
            : readableBytes(node.bytes);
          if (node.events.length > 0) {
            const eventMsgs = node.events.map((event) => `
              ${event.msg} between ${moment(event.startTime).format(momentTimeFormat.time)} and
                ${moment(event.endTime).format(momentTimeFormat.time)}.`);
            const totalMsg = node.remainingEventCount > 0 ? `${node.remainingEventCount} more event(s)` : '';
            return [tooltipBody, eventMsgs.join('<br />'), totalMsg].join('<hr />');
          }
          return tooltipBody;
        })
        .classed(EVENT_CLASS, (d) => d.events.length > 0);

      this.chord
        .attr('data-html', true)
        .attr('data-toggle', 'popover')
        .attr('data-title', (flow) => this.flowTitle(flow, mapIdToName))
        .attr('data-placement', 'left')
        .attr('data-content', (flow) => [
          `<div>${mapIdToName[flow.app.id]} ${ARROW_SYMBOL} ${mapIdToName[flow.user.id]}</div>`,
          `<div>Protocol:<span>${flow.protocol}</span></div>`,
          this.flowContent(flow.app, flow.user).toString(),
          '<hr />',
          `<div>${mapIdToName[flow.user.id]} ${ARROW_SYMBOL} ${mapIdToName[flow.app.id]}</div>`,
          `<div>Protocol:<span> ${flow.protocol}</span></div>`,
          this.flowContent(flow.user, flow.app).toString(),
        ].join('\n'));

      $('[data-toggle="popover"]')
        .popover()
        .on(
          'show.bs.popover',
          (event) => {
            $($(event.currentTarget).data('bs.popover').getTipElement()).css('max-width', '400px');
          },
        );
    },

    flowTitle(flow, mapIdToName) {
      return `${mapIdToName[flow.app.id]} ${DOUBLE_ARROW_SYMBOL} ${mapIdToName[flow.user.id]}`;
    },

    flowContent(source, target) {
      if (target.bytes > 0) {
        const { traffics } = target;
        const trafficText = this.TrafficHtml(traffics[0]);
        return `<div>${readableBytes(target.bytes)}</div><div>${trafficText}</div>`;
      }
      return '<div>No Traffic</div>';
    },

    TrafficHtml(traffic) {
      const trafficInfo = traffic.vlanId ? [`<div>VLAN ID: ${traffic.vlanId}</div>`] : [];
      if (traffic.port) trafficInfo.push(`<div>Ports: ${traffic.port}</div>`);
      if (traffic.pkts > 0) {
        trafficInfo.push(`<div>Packets: ${traffic.pkts}</div>`);
        trafficInfo.push(`<div>Average Packet Size: ${readableBytes(Math.floor(traffic.bytes / traffic.pkts))}</div>`);
      }
      return trafficInfo.join('\n');
    },

    arcKey(arc) {
      return arc.id;
    },

    // Constructs a chord layout from the array of flows and returns the arcs and chords.
    chordGraphLayout(flows) {
      if (flows.length === 0) return { arcs: [], chords: [] };
      const arcsMap = this.createArcsMap(flows);
      const arcArray = this.arcsMapToArray(arcsMap);
      const total = d3.sum(arcArray, (arc) => arc.bytes);
      const bytesToAngleScale = ((2 * Math.PI) - TOTAL_ARC_PADDING) / total;
      const [arcs, allFlows] = this.createArcs(arcArray, bytesToAngleScale);
      const chords = this.createChords(arcsMap, allFlows);
      return { arcs, chords };
    },

    // Generate the information for the arcs as a map by the arc's ID.
    // The top arc has its own ID. The other arcs' ID will be the IP address.
    createArcsMap(flows) {
      const arcsMap = {};
      arcsMap[TOP_ARC_ID] = this.createArc(
        TOP_ARC_ID,
        flows.cmptNodes[0].events,
        flows.cmptNodes[0].remainingEventCount,
      );
      const topArc = arcsMap[TOP_ARC_ID];
      flows.appUserNodes.forEach((node) => {
        if (arcsMap[node.id] == null) arcsMap[node.id] = this.createArc(node.id, node.events, node.remainingEventCount);
      });
      flows.links.forEach((link) => {
        const userArc = arcsMap[link.userId];
        this.processArcProtocolInfo(
          topArc,
          link.userId,
          link.proto,
          link.port || '',
          link.vlanId,
          link.userToCmptBytes,
          link.userToCmptPkts,
        );
        this.processArcProtocolInfo(
          userArc,
          TOP_ARC_ID,
          link.proto,
          link.port || '',
          link.vlanId,
          link.cmptToUserBytes,
          link.cmptToUserPkts,
        );
      });
      return arcsMap;
    },

    createArc(id, events, remainingEventCount) {
      return {
        id,
        flowInfos: {},
        bytes: 0,
        events,
        remainingEventCount,
      };
    },

    // Store the protocol information for an arc receiving the traffic from a given arc ID.
    processArcProtocolInfo(arc, id, protocol, port, vlanId, bytes, pkts) {
      const arcFlow = arc;
      arcFlow.bytes += bytes;
      const key = this.flowInfoKey(id, protocol);
      // Initialize the flow info if needed.
      if (arcFlow.flowInfos[key] == null) arcFlow.flowInfos[key] = this.initialFlowInfo(id, protocol);
      // Aggregate the flow information.
      const flowInfo = arcFlow.flowInfos[key];
      flowInfo.bytes += bytes;
      flowInfo
        .traffics
        .push({
          port,
          vlanId,
          pkts,
          bytes,
        });
    },

    // The unique key for identifying a flow coming into an arc.
    flowInfoKey(id, protocol) {
      return `${id}-${protocol}`;
    },

    initialFlowInfo(id, protocol) {
      return {
        sourceId: id, protocol, bytes: 0, traffics: [],
      };
    },

    // Convert the arcs map into a sorted array, including the flows for each arc.
    arcsMapToArray(arcsMap) {
      const arcArray = [];
      const keys = Object.keys(arcsMap);
      keys.forEach((key) => {
        // Sort the flows in descending order by their bytes.
        const arc = arcsMap[key];
        arc.flows = [];
        const flowInfosKeys = Object.keys(arc.flowInfos);
        flowInfosKeys.forEach((flowInfosKey) => arc.flows.push(arc.flowInfos[flowInfosKey]));
        arc.flows.sort(this.sortBytesDescending);
        arcArray.push(arc);
      });
      return arcArray.sort(this.sortArcs);
    },

    sortBytesDescending(a, b) {
      return b.bytes - a.bytes;
    },

    // The sort function for the arcs. Ensures the top arc appears first and use descending order by bytes for the other
    // arcs.
    sortArcs(arc1, arc2) {
      if (arc1.id === TOP_ARC_ID) {
        return -1;
      } if (arc2.id === TOP_ARC_ID) {
        return 1;
      }
      return this.sortBytesDescending(arc1, arc2);
    },

    // Create the bidirectional chords for each flow. Angles are always assigned for the flow coming from the target.
    createChords(arcsMap, allFlows) {
      return Object.keys(allFlows).map((key) => {
        // Get the reverse flow to determine the angles.
        const targetFlow = allFlows[key];
        const sourceKey = this.flowKey(targetFlow.targetId, targetFlow.sourceId, targetFlow.protocol);
        let sourceFlow = allFlows[sourceKey];
        if (sourceFlow == null) {
          // If the reverse flow doesn't exist, create a default flow using angles from its arc.
          const arc = arcsMap[targetFlow.sourceId];
          sourceFlow = this.createDefaultChord(targetFlow, arc.endAngle);
        }
        const [app, user] = targetFlow.targetId === TOP_ARC_ID
          ? [targetFlow, sourceFlow]
          : [sourceFlow, targetFlow];
        return ({
          protocol: targetFlow.protocol,
          source: this.simplifyFlowAngles(sourceFlow),
          target: this.simplifyFlowAngles(targetFlow),
          app: this.simplifyFlowInfo(app),
          user: this.simplifyFlowInfo(user),
        });
      });
    },

    chordFlowKey(flow) {
      return `${flow.user.id}-${flow.protocol}`;
    },

    flowKey(sourceId, targetId, protocol) {
      return `${sourceId}-${targetId}-${protocol}`;
    },

    createArcs(arcArray, bytesToAngleScale) {
      // Assign the start and end angles for each arc.
      const anglePadding = TOTAL_ARC_PADDING / arcArray.length;
      // Keeps track of the angles to assign. This initial offset ensures that the first arc is in the middle.
      let nextAngle = (-arcArray[0].bytes * bytesToAngleScale) / 2;
      // Keep track of all flow info from the arcs.
      const allFlows = {};
      const arcs = [];
      arcArray.forEach((arc) => {
        let startAngle;
        const arcFlow = arc;
        const arcStartAngle = nextAngle;
        // Assign angles to each flow.
        arcFlow.flows.forEach((flow) => {
          startAngle = nextAngle;
          nextAngle += flow.bytes * bytesToAngleScale;
          const key = this.flowKey(flow.sourceId, arcFlow.id, flow.protocol);
          allFlows[key] = this.createFlow(flow, arcFlow.id, startAngle, nextAngle);
        });
        arcFlow.startAngle = arcStartAngle;
        arcFlow.endAngle = nextAngle;
        arcFlow.vlanIds = new Set(arc.flows.map((flow) => flow.traffics.map((traffic) => traffic.vlanId)).flat());
        nextAngle += anglePadding;
        arcs.push(arcFlow);
      });
      return [arcs, allFlows];
    },

    createFlow(flow, arcId, startAngle, endAngle) {
      return {
        sourceId: flow.sourceId,
        targetId: arcId,
        protocol: flow.protocol,
        startAngle,
        endAngle,
        bytes: flow.bytes,
        traffics: flow.traffics.sort(this.sortBytesDescending),
      };
    },

    createDefaultChord(flow, angle) {
      return {
        sourceId: flow.targetId,
        targetId: flow.sourceId,
        startAngle: angle,
        endAngle: angle,
        bytes: 0,
        traffics: [],
      };
    },

    // Keep the information from the flow that is needed for rendering the chord.
    simplifyFlowAngles(flow) {
      return { startAngle: flow.startAngle, endAngle: flow.endAngle };
    },

    // Keep the necessary incoming flow information.
    simplifyFlowInfo(flow) {
      return {
        id: flow.targetId,
        bytes: flow.bytes,
        traffics: flow.traffics,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.flow-chord-graph {
  .chord {
    opacity: 0.25;

    &.event {
      opacity: 0.5;
    }

    &:hover {
      opacity: 0.75;
    }
  }

  .inactive {
    .chord {
      opacity: 0.05;
      pointer-events: none;
    }
  }

  .event {
    stroke: red;
    stroke-width: 2px;
  }
}

.popover {
  background: #212744 !important;

  .arrow {
    display: none !important;
  }
}

.popover-header {
  background: #212744 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.popover-body {
  color: white !important;
}
</style>
