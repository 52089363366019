export const reportTypes = {
  topN: 'Top-N',
  devices: 'Devices',
  xtendPackages: 'Xtend Installed Packages',
};
export const reportInfo = {
  [reportTypes.topN]: {
    name: 'Top N',
  },
  [reportTypes.devices]: {
    name: 'Devices',
  },
  [reportTypes.xtendPackages]: {
    name: 'Xtend Installed Packages',
  },
};
export const reportStatuses = {
  ready: 'ready',
  pending: 'pending',
};
