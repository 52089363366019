<template>
  <div>
    <b-card no-body>
      <b-table
        id="events-table"
        class="table-with-actions-min-height"
        responsive
        small
        primary-key="id"
        :items="events"
        :fields="tableFields"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: colWidth(field.key), minWidth: colMinWidth(field.key) }"
          >
        </template>
        <template #cell(time)="row">
          <b-row>
            <b-col>
              {{ formatDate(row.value) }}
            </b-col>
          </b-row>
        </template>
        <template #cell(entities)="row">
          <div>
            <span :id="`popover-${entitiesHead(row.value)}`" v-html="entitiesHead(row.value)"></span>
            <b-popover
                :target="`popover-${entitiesHead(row.value)}`"
                placement="bottomright"
                triggers="hover focus"
              >
                <div v-for="entity in row.value.slice(1)" :key="entity.id" class="mb-1">
                  <strong>Type: {{entity.type}} </strong> <br>
                  <strong>Name: {{entity.name}}</strong>
                </div>
            </b-popover>
          </div>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="paginationTotalRows"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        align="right"
        class="mb-0 mt-1"
        ariaControls="events-table"
      ></b-pagination>
    </b-card>
  </div>
</template>

<script>
import moment from 'moment';

import colorPalette from '@/xvisor/constants/colorPalette';
import momentTimeFormat from '@/xvisor/constants/momentTimeFormat';

export default {
  props: {
    alertId: {
      type: String,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      events: [],
      perPage: 20,
      currentPage: 1,
      tableFields: [
        { key: 'type', label: 'Event Type', sortable: true },
        { key: 'time', label: 'Time' },
        { key: 'eventMessage', label: 'Event Message' },
        { key: 'entities', label: 'Entities' },
      ],
      colorPalette,
    };
  },
  computed: {
    eventTableUrl() {
      return `/alerts/${this.alertId}/events/list.json`;
    },
    paginationTotalRows() {
      return this.events.length;
    },
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    httpGet() {
      this.$http
        .get(this.eventTableUrl, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
          },
        })
        .then((response) => { this.events = response.data; });
    },
    entitiesHead(entities) {
      const head = entities[0];
      return `
        <span class="text-primary">Type: ${head.type}</span> <br>
        <span class="text-primary">Name: ${head.name}...</span>
      `;
    },
    formatDate(dateStr) {
      return moment(dateStr).format(momentTimeFormat.dateTime);
    },
    colWidth(fieldKey) {
      switch (fieldKey) {
        case 'type': return '10%';
        case 'time': return '10%';
        case 'eventMessage': return '35%';
        case 'entities': return '10%';
        default: return '180px';
      }
    },
    colMinWidth(fieldKey) {
      switch (fieldKey) {
        case 'type': return '100px';
        case 'time': return '100px';
        case 'eventMessage': return '300px';
        case 'entities': return '100px';
        default: return '80px';
      }
    },
  },
};
</script>
