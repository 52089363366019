<template>
  <div>
    <e-chart
      v-if="hasData"
      :options="chartOptions"
      autoresize
      id="iface-line-chart"
    ></e-chart>
    <div v-else-if="loaded">{{ $t("No Data Available") }}</div>
    <SpinnerCmpt v-else></SpinnerCmpt>
  </div>
</template>

<script>
import moment from 'moment';

import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import colorPalette from '@/xvisor/constants/colorPalette';
import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';
import granularity from '@/xvisor/utilities/granularity';
import infraIpsMixin from '@/xvisor/components/infra/infraIpsMixin';
import themeStyle from '@/xvisor/utilities/themeStyle';
import timeFormat from '@/xvisor/utilities/timeFormat';

export default {
  components: {
    SpinnerCmpt,
  },
  mixins: [infraIpsMixin],
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    selectedIface: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      series: [],
      loaded: false,
    };
  },
  watch: {
    selectedIface() {
      this.httpGet();
    },
    timeRange() {
      this.httpGet();
    },
  },
  mounted() {
    this.httpGet();
  },
  computed: {
    timeSet() {
      return new Set(this.series.map((item) => item.time));
    },
    hasData() {
      return this.loaded && this.series && this.series.length;
    },
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
    timeSeriesFormat() {
      return timeFormat.momentFormat(this.timeSet, new Date());
    },
    formattedSeries() {
      return this.series.map((point) => [
        moment(point.time).valueOf(),
        (point.value * 100).toFixed(0),
      ]);
    },
    interfaceLineChartUrl() {
      return `/infra/routers/${this.infraIp}/iface/${this.selectedIface.snmpIndex}/utilizations.json`;
    },
    chartOptions() {
      return {
        color: [colorPaletteShade.teal4],
        tooltip: {
          trigger: 'axis',
          formatter: (info) => `
            <div class="tooltip-title">
              ${moment(info[0].value[0]).format(this.timeSeriesFormat)}
              <br>
              <strong>Utilization</strong>: ${info[0].value[1]}%
            </div>
          `,
          axisPointer: {
            type: 'line',
            animation: false,
            label: {
              backgroundColor: colorPalette.tooltipBackground,
            },
          },
        },
        grid: [
          {
            left: 50,
            right: 40,
            top: 10,
            bottom: 50,
          },
        ],
        xAxis: {
          type: 'time',
          boundaryGap: false,
          axisTick: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLine: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLabel: {
            formatter: (value) => moment(value).format(this.timeSeriesFormat),
            fontSize: 9,
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: [
          {
            type: 'value',
            name: 'Utilization',
            nameLocation: 'center',
            nameGap: 35,
            axisTick: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLine: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLabel: {
              formatter: (value) => `${value}%`,
              fontSize: 9,
            },
          },
        ],
        series: [
          {
            name: 'Utilization',
            type: 'line',
            data: this.formattedSeries,
            areaStyle: {},
          },
        ],
      };
    },
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.interfaceLineChartUrl, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
            stepMinutes: granularity.granularity12(this.timeRange),
          },
        })
        .then((response) => { this.series = response.data; })
        .finally(() => { this.loaded = true; });
    },
  },
};
</script>

<style scoped>
.echarts {
  width: 100% !important;
  height: 200px !important;
}
#iface-line-chart {
  height: 150px !important;
}
</style>
