<template>
  <div>
    <e-chart
      v-if="hasData"
      :options="chartOptions"
      :class="['apache-echarts-default-graph', 'mt-1-5']"
      autoresize
    ></e-chart>
    <div v-else-if="loaded" :class="mt-1-5">{{ $t("No Data Available") }}</div>
    <SpinnerCmpt v-else></SpinnerCmpt>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import colorPalette from '@/xvisor/constants/colorPalette';
import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';
import granularity from '@/xvisor/utilities/granularity';
import themeStyle from '@/xvisor/utilities/themeStyle';
import timeFormat from '@/xvisor/utilities/timeFormat';

const colors = [
  colorPaletteShade.blue4,
  colorPaletteShade.blue7,
  colorPaletteShade.green4,
  colorPaletteShade.green7,
  colorPaletteShade.orange4,
  colorPaletteShade.orange7,
  colorPaletteShade.red4,
  colorPaletteShade.red7,
  colorPaletteShade.purple4,
  colorPaletteShade.purple7,
  colorPaletteShade.yellow4,
  colorPaletteShade.yellow7,
  colorPaletteShade.teal4,
  colorPaletteShade.teal7,
  colorPaletteShade.indigo4,
  colorPaletteShade.indigo7,
  colorPaletteShade.pink4,
  colorPaletteShade.pink7,
  colorPaletteShade.gray4,
  colorPaletteShade.gray7,
];

export default {
  components: {
    SpinnerCmpt,
  },
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    urls: {
      type: Object,
      required: true,
    },
    targetId: {
      type: Number,
      required: true,
    },
    issueTimeSelected: {
      type: Number,
    },
  },
  data() {
    return {
      delaySeries: [],
      lossSeries: [],
      eventSeries: [],
      series: [],
      loaded: false,
    };
  },
  computed: {
    probeEventParams() {
      return {
        params: {
          start: this.timeRange.start.toISOString(),
          end: this.timeRange.end.toISOString(),
        },
      };
    },
    httpParams() {
      return {
        params: {
          start: this.timeRange.start.toISOString(),
          end: this.timeRange.end.toISOString(),
          stepMinutes: granularity.granularity72(this.timeRange),
        },
      };
    },
    initialMarkline() {
      // Initializes the line to the end of the graph.
      const times = [...this.timeSet].map((time) => moment(time).valueOf());
      const seriesData = this.delaySeries
        .filter((ts) => ts.points.length !== 0)
        .map((ts) => ({
          data: times.map((time) => {
            const timePoint = ts.points.find((point) => moment(point.time).valueOf() === time);
            return [time, timePoint ? timePoint.value : 0];
          }),
        }));
      return seriesData[0].data.at(-1)[0];
    },
    timeSet() {
      return new Set(this.delaySeries.map((ts) => ts.points.map((point) => point.time)).flat());
    },
    hasData() {
      return this.loaded
        && this.delaySeries && this.delaySeries.length > 0
        && (this.delaySeries[0] && this.delaySeries[0].points && this.delaySeries[0].points.length > 0);
    },
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
    toggleTooltipColor() {
      return themeStyle.styleToggle(colorPalette.white, colorPalette.tooltipBackground);
    },
    timeSeriesFormat() {
      return timeFormat.momentFormat(this.timeSet, new Date());
    },
    formattedSeries() {
      const times = [...this.timeSet].map((time) => moment(time).valueOf());
      const series = [];
      this.delaySeries.forEach((ts) => {
        series.push(ts);
      });
      this.lossSeries.forEach((ts) => {
        series.push(ts);
      });
      return series
        .filter((ts) => ts.points.length !== 0)
        .map((ts) => ({
          name: ts.name,
          type: 'line',
          showSymbol: false,
          markLine: {
            symbol: 'circle',
            lineStyle: {
              type: ts.name.includes('Baseline') ? 'dashed' : 'solid',
            },
            data: [{ xAxis: this.issueTimeSelected ? this.issueTimeSelected : this.initialMarkline }],
            label: {
              formatter: (info) => moment(info.value).format(this.timeSeriesFormat),
            },
          },
          markPoint: {
            symbol: 'triangle',
            symbolSize: 15,
            label: {
              offset: [0, 5],
              fontSize: 10,
            },
            itemStyle: {
              color: 'red',
            },
            tooltip: {
              trigger: 'item',
              formatter: (info) => {
                const cell = info.data;
                const header = `
                <div class="apache-echarts-tooltip">
                    <div>
                    ${info.marker} ${info.name} / ${moment(cell.xAxis).format(this.timeSeriesFormat)}
                    </div>
                `;
                const eventsRow = `<div># Events: ${cell.value > 0 ? cell.value.toFixed(0) : 0}</div>`;
                const unit = this.calculateUnit(cell.name);
                let eventMsgs = '';
                const { xAxis, yAxis, baseline } = cell;
                if (yAxis === 0 || baseline === 0) {
                  eventMsgs = '';
                  return `${[header, eventsRow, ...eventMsgs].join('')} </div>`;
                }
                let percentNum = 0;
                let orgName = '';
                if (info.name === 'Domain Link Delay') {
                  orgName = cell.orgName;
                }
                if (yAxis > baseline) {
                  percentNum = (((yAxis / baseline) - 1) * 100).toFixed(2);
                  eventMsgs = `
                    <div>
                    ${moment(xAxis).format(this.timeSeriesFormat)}:
                        ${orgName} ${percentNum}% over baseline (${baseline.toFixed(2)}${unit})
                    </div>
                `;
                } else if (yAxis < baseline) {
                  percentNum = ((1 - (yAxis / baseline)) * 100).toFixed(2);
                  eventMsgs = `
                    <div>
                    ${moment(xAxis).format(this.timeSeriesFormat)}:
                    ${orgName} ${percentNum}% under baseline (${baseline.toFixed(2)}${unit})
                    </div>
                `;
                }
                return `${[header, eventsRow, ...eventMsgs].join('')} </div>`;
              },
            },
            data: this.eventSeries ? this.markPointHelper : [],
          },
          data: times.map((time) => {
            const timePoint = ts.points.find((point) => moment(point.time).valueOf() === time);
            return [time, timePoint ? timePoint.value : 0];
          }),
        }));
    },
    chartOptions() {
      return {
        color: colors,
        tooltip: {
          trigger: 'axis',
          formatter: (info) => `
            <div class="apache-echarts-tooltip">
            ${moment(info[0].value[0]).format(this.timeSeriesFormat)}
            <br>
            ${this.chartTooltipContent(info)}
            </div>
          `,
        },
        grid: [
          {
            left: 50,
            right: 60,
            top: 30,
            bottom: 30,
          },
        ],
        xAxis: {
          type: 'time',
          boundaryGap: false,
          splitLine: {
            show: false,
          },
          axisTick: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLine: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLabel: {
            formatter: (value) => moment(value).format(this.timeSeriesFormat),
            fontSize: 9,
          },
        },
        yAxis: [
          {
            type: 'value',
            name: 'Delay (ms)',
            nameLocation: 'center',
            nameGap: 35,
            min: 0,
            max: this.maxHeightOfChart,
            axisTick: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLine: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLabel: {
              formatter: (value) => value.toFixed(1),
              fontSize: 9,
            },
          },
          {
            type: 'value',
            name: 'Loss (%)',
            nameLocation: 'center',
            nameGap: 35,
            min: 0,
            max: 100,
            axisTick: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLine: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLabel: {
              formatter: (value) => `${value}`,
              fontSize: 9,
            },
          },
        ],
        series: this.formattedSeries,
      };
    },
    maxHeightOfChart() {
      let maxHeight = 0;
      const times = [...this.timeSet].map((time) => moment(time).valueOf());
      const timeValueMap = new Map();
      times.forEach((time) => {
        this.delaySeries.filter((ts) => ts.points.length !== 0)
          .forEach((ts) => {
            const timePoint = ts.points.find((point) => moment(point.time).valueOf() === time);
            if (timePoint) {
              timeValueMap.set(timePoint.time,
                timeValueMap.get(timePoint.time)
                  ? timeValueMap.get(timePoint.time) + timePoint.value
                  : timePoint.value);
            } else {
              maxHeight = Math.max(maxHeight, timePoint ? timePoint.value : 0);
            }
          });
      });
      timeValueMap.forEach((val) => {
        maxHeight = Math.max(maxHeight, val);
      });
      this.markPointHelper.forEach((event) => {
        maxHeight = Math.max(maxHeight, event.yAxis);
      });
      return maxHeight;
    },
    markPointHelper() {
      return this.eventSeries.map((event) => ({
        xAxis: event.time,
        yAxis: event.value,

        // There will always be 1 event for each marker.
        value: 1,
        baseline: event.baseline,
        name: event.name,
      }));
    },
  },
  watch: {
    targetId() {
      this.httpGet();
    },
    timeRange() {
      this.httpGet();
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    calculateUnit() {
      return 'ms';
    },
    httpGet() {
      this.loaded = false;
      axios
        .all([
          this.$http.get(this.urls.delayUrl, this.httpParams),
          this.$http.get(this.urls.lossUrl, this.httpParams),
          this.$http.get(this.urls.eventUrl, this.httpParams),
        ])
        .then(axios.spread((delayRes, lossRes, eventRes) => {
          this.delaySeries = delayRes.data;
          this.lossSeries = lossRes.data;
          this.eventSeries = eventRes.data;
        }))
        .finally(() => { this.loaded = true; });
    },
    chartTooltipContent(info) {
      let result = '';
      for (let i = 0; i < info.length; i += 1) {
        if (info[i].seriesName.includes('AvgLoss')) {
          result += `${info[i].marker} ${info[i].seriesName}: ${info[i].value[1].toFixed(1)} % <br>`;
        } else {
          result += `${info[i].marker} ${info[i].seriesName}: ${info[i].value[1].toFixed(1)} ms <br>`;
        }
      }
      return result;
    },
  },
};
</script>
