import { render, staticRenderFns } from "./PackageTab.vue?vue&type=template&id=4bd111bd&scoped=true&"
import script from "./PackageTab.vue?vue&type=script&lang=js&"
export * from "./PackageTab.vue?vue&type=script&lang=js&"
import style0 from "./PackageTab.vue?vue&type=style&index=0&id=4bd111bd&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bd111bd",
  null
  
)

export default component.exports