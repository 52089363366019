<template>
  <b-col cols="12">
    <b-row>
      <!-- Primary Entity -->
      <b-col cols="7">
        <validation-provider name="Entity Count" rules="required" v-slot="{ errors }">
          <b-form-group label="Find Top*" label-cols-md="7">
            <b-form-select
              v-model="params.primaryEntityCount"
              :options="entityCount"
              :state="errors[0] ? false : null"
              @change="emitUpdateSelection"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>Entity Count</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col cols="5">
        <validation-provider name="Primary Entity Type" rules="required" v-slot="{ errors }">
          <b-form-group label-cols-md="0">
            <b-form-select
              v-model="params.primaryEntityType"
              :options="primaryEntityTypes"
              :state="errors[0] ? false : null"
              @change="emitUpdateSelection"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>Primary Entity Type</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <!-- Secondary Entity (optional) -->
      <b-col cols="7">
        <b-form-group label="Within each primary, find top (optional)" label-cols-md="7">
          <b-form-select
            v-model="params.secondaryEntityCount"
            :options="entityCount"
            @change="emitUpdateSelection"
          >
            <template #first>
              <b-form-select-option :value="null" disabled>Entity Count</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="5">
        <b-form-group label-cols-md="0">
          <b-form-select
            v-model="params.secondaryEntityType"
            :options="secondaryEntityTypes"
            @change="emitUpdateSelection"
          >
            <template #first>
              <b-form-select-option :value="null" disabled>Secondary Entity Type</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- Metric Type -->
    <b-row>
      <b-col cols="12">
        <validation-provider name="Metric Type" rules="required" v-slot="{ errors }">
          <b-form-group label="By*" label-cols-md="4">
            <b-form-select
              v-model="params.metricType"
              :options="metricTypes"
              :state="errors[0] ? false : null"
              @change="emitUpdateSelection"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>Metric Type</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
export default {
  data() {
    return {
      entityCount: [5, 10, 20],
      primaryEntityTypes: [
        'applications',
        'infra-elements',
        'users',
        'servers',
      ],
      metricTypes: [
        'bytes',
        'packets',
        'flows',
      ],
      params: {
        primaryEntityType: null,
        primaryEntityCount: null,
        secondaryEntityType: null,
        secondaryEntityCount: null,
        metricType: null,
      },
    };
  },
  computed: {
    // Secondary Entity Types would be everything but what is chosen as the primary type.
    secondaryEntityTypes() {
      return this.primaryEntityTypes.filter((type) => type !== this.params.primaryEntityType);
    },
  },
  methods: {
    emitUpdateSelection() {
      this.$emit('change-topn-params', this.params);
    },
  },
};
</script>
