<template>
  <div>
    <b-card no-body>
      <b-row class="mx-1 mt-2 mb-1" align-h="between">
        <b-col lg="4" md="12" sm="12">
          <b-row align-h="between">
            <b-col lg="4" md="4" sm="12" class="mb-1">
              <div class="form-select">
                Show
                <b-form-select
                  v-model="perPage"
                  :options="options"
                  style="width: 60px;"
                  size="sm"
                ></b-form-select>
                Entries
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="2" md="7" sm="7" class="mb-1">
          <ThreatFilter
            v-on:threatFilterChange="threatFilterUpdate"
            :threatOptions="threatOptions"
          >
          </ThreatFilter>
        </b-col>
        <b-col lg="3" md="12" sm="12" class="mb-1">
          <SearchBar :hasErrorMessage="true">
            <template #input>
              <b-form-input v-model="filter" type="search" placeholder="Search" />
            </template>
          </SearchBar>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div v-if="hasData">
            <b-table
              class="table-with-actions-min-height px-2"
              responsive
              striped
              outlined
              :items="ipsFilter"
              :fields="computedTableFields"
              :per-page="perPage"
              :current-page="currentPage"
              :filter="filter"
              @filtered="onFiltered"
              :filter-function="filterTable"
              select-mode="single"
              selectable
            >
              <template #cell(programs)="row">
                <Programs
                  :programInfo="row.item.programs"
                  :uniqueId="row.item.ip">
                </Programs>
              </template>
              <template #cell(deviceCount)="row">
                {{ formatNumberWithCommas(row.value) }}
              </template>
              <template #cell(totalConnectionCount)="row">
                {{ formatNumberWithCommas(row.value) }}
              </template>
              <template #cell(threatFeeds)="row">
                <ThreatFeedIcons
                  :threatFeedInfo="row.item.threatFeeds"
                  :threatFeedTypes="threatFeedTypes"
                  :uniqueId="row.item.ip">
                </ThreatFeedIcons>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="paginationTotalRows"
              :per-page="perPage"
              align="right"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-2 mx-1"
            />
          </div>
          <div v-else-if="loaded" class="mx-1 pl-1 mb-1">
            {{ $t("No Data Available") }}
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios';
import Please from 'pleasejs';

import SearchBar from '@/xvisor/components/SearchBar.vue';
import Programs from '@/xvisor/components/security/IpTablePrograms.vue';
import formatNumberWithCommas from '@/xvisor/utilities/addCommaToNumber';
import ThreatFeedIcons from '@/xvisor/components/ThreatFeedIcons.vue';
import ThreatFilter from '@/xvisor/components/security/ThreatFilter.vue';

export default {
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  components: {
    SearchBar,
    Programs,
    ThreatFeedIcons,
    ThreatFilter,
  },
  data() {
    return {
      ipsInfo: [],
      getThreatFeedsUrl: '/security/threatfeeds.json',
      loaded: false,
      selectedThreatFilters: [],
      threatOptions: [],
      currentPage: 1,
      perPage: 10,
      options: [
        { value: 5, text: '5' },
        { value: 10, text: '10' },
        { value: 15, text: '15' },
      ],
      threatFeedTypes: {
        all: {
          name: 'Select All',
          type: 'all',
        },
      },
      filter: null,
      filteredLength: null,
      tableFields: [
        { key: 'ip', label: 'Destination', sortable: true },
        { key: 'asn', label: 'ASN', sortable: true },
        { key: 'location', label: 'Location', sortable: true },
        { key: 'deviceCount', label: 'Devices', sortable: true },
        { key: 'programs', label: 'Programs', sortable: false },
        { key: 'totalConnectionCount', label: 'Total Connections', sortable: true },
        { key: 'threatFeeds', label: 'Threat', sortable: true },
      ],
    };
  },
  computed: {
    hasData() {
      return this.loaded && this.ipsInfo && this.ipsInfo.length > 0;
    },
    paginationTotalRows() {
      if (this.filter) return this.filteredLength;
      return this.ipsInfo.length;
    },
    computedTableFields() {
      return this.tableFields;
    },
    ipsFilter() {
      this.getThreatTypeAndCount();
      return this.ipsInfo.filter((server) => this.includeBySelectedThreatType(server));
    },
  },
  watch: {
    url() {
      this.httpGet();
    },
    timeRange() {
      this.httpGet();
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    httpGet() {
      this.loaded = false;
      axios
        .all([
          this.$http.get(this.url, {
            params: {
              start: this.timeRange.start.toISOString(),
              end: this.timeRange.end.toISOString(),
            },
          }),
          this.$http.get(this.getThreatFeedsUrl),
        ])
        .then(axios.spread((ipsInfoRes, threatFeedsRes) => {
          this.ipsInfo = ipsInfoRes.data;
          // Store possible threat types in a key-value object.
          threatFeedsRes.data.forEach((val) => { this.threatFeedTypes[val.type] = val; });
          this.getThreatTypeAndCount();
          this.getThreatIconColors();
        }))
        .finally(() => { this.loaded = true; });
    },
    includeBySelectedThreatType(server) {
      if ((!this.selectedThreatFilters.length)
        || (this.selectedThreatFilters[0] === 'all' && server.threatFeeds && server.threatFeeds.length > 0)
        || (server.threatFeeds && this.threatTypeExists(server.threatFeeds))) {
        return true;
      }
      return false;
    },
    threatTypeExists(threatFeeds) {
      let threatMatches = true;
      this.selectedThreatFilters.forEach((threat) => {
        threatMatches = threatFeeds.includes(threat);
      });
      return threatMatches;
    },
    threatFilterUpdate(threatFilters) {
      this.selectedThreatFilters = threatFilters;
      this.filteredLength = this.ipsFilter.length;
      this.currentPage = 1;
    },
    getThreatTypeAndCount() {
      // Determine threat type and their counts after filters are applied.
      const feedTypes = Object.values(this.threatFeedTypes);

      this.threatOptions = feedTypes.map((option) => {
        let count = 0;
        this.ipsInfo.forEach((item) => {
          if (item.threatFeeds && item.threatFeeds.length) {
            if (option.type === 'all') {
              count += 1;
            } else {
              item.threatFeeds.forEach((feed) => {
                if (feed === option.type) {
                  count += 1;
                }
              });
            }
          }
        });
        return { threatName: option.name, threatType: option.type, threatCount: count };
      });
    },
    getThreatIconColors() {
      const feedTypesLength = Object.keys(this.threatFeedTypes).length;
      const hsvs = [
        {
          h: 24,
          s: 1,
          v: 0.5,
        },
        {
          h: 220,
          s: 1,
          v: 0.7,
        },
        {
          h: 264,
          s: 1,
          v: 0.5,
        },
      ];
      const allColors = hsvs.map((hsv) => {
        const scheme = Please.make_scheme(
          hsv,
          {
            scheme_type: 'analogous',
            format: 'hex',
          },
        );
        return scheme;
      }).flat();
      const randomColors = allColors.sort(() => 0.5 - Math.random()).slice(0, feedTypesLength);
      Object.keys(this.threatFeedTypes).forEach((key, index) => {
        this.threatFeedTypes[key].style = randomColors[index];
      });
    },
    onFiltered(filteredItems) {
      this.filteredLength = filteredItems.length;
      this.currentPage = 1;
    },
    formatNumberWithCommas,
  },
};
</script>

<style scoped>
.form-select{
  min-width: 160px;
  width: 20%;
}
</style>
