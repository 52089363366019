<template>
  <div>
    <b-card
      :title="$t(componentObject.title)"
      :class="panelSizeHalf ? 'panel-size-half' : 'panel-size-full'" >
      <SearchPanelButtons
        :isSaved="componentObject.isSaved"
        @save-panel="savePanel"
        @delete-panel="deletePanel"
      />
      <b-card
        v-if="componentObject.aiResponse.length"
        class="ai-card"
      >
        <div class="d-flex flex-column ">
          <span class="font-italic">
            ✨ This is an AI generated response ✨
          </span>
          <span class="mt-2">
            {{ componentObject.aiResponse }}
          </span>
        </div>
      </b-card>
      <component
        :class="panelSizeHalf ? 'component-size' : ''"
        :is="componentObject.component"
        :url="componentObject.url"
        :timeRange="timeRange"
        :reduceTableHeight="panelSizeHalf"
      />
    </b-card>
  </div>
</template>

<script>
import SearchPanelButtons from '@/xvisor/components/launchPad/SearchPanelButtons.vue';

export default {
  components: {
    SearchPanelButtons,
  },
  props: {
    componentObject: {
      type: Object,
      required: false,
    },
    timeRange: {
      type: Object,
      required: true,
    },
    panelSizeHalf: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    savePanel() {
      this.$emit('save-panel', this.componentObject.componentId);
    },
    deletePanel() {
      this.$emit('delete-panel', this.componentObject.componentId);
    },
  },
};
</script>

<style scoped>
.panel-size-full{
  height: 74vh;
}
.panel-size-half{
  height: 55vh;
}
.component-size{
  max-height: 37vh;
}
.ai-card {
  background-color: #161d31;
}
</style>
