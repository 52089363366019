<template>
  <b-row class="mx-1 w-full justify-content-center text-sm">
    <b-card v-if="hasData" class="trouble-shooting-box">
      <b-row>
        <b-col>
          <b-button
            class="float-right mt-n1 p-0 mr-n1"
            @click="$emit('close')"
            variant="flat-primary"
            v-if="indexPre !== 0"
          >
            <b-icon icon="x" font-scale="2"/>
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b>{{ alert.type }}: </b> {{ alert.msg }}
        </b-col>
      </b-row>
      <b-row v-if="this.hintsInfo">
        <b-col>
          <b>Hints: </b>
          <p v-for="(hint, index) in this.hintsInfo" :key="`ts-${index}`">
            Time: {{ formatDate(hint.time) }} Type: {{ hint.type }} <br>
            Message: {{ hint.eventMessage }} <br>
            <b-badge
              variant="primary"
              v-for="(entity, index) in hint.entities"
              :key="`ts-${index}`"
            >
              {{ entity.type }}: {{ entity.name }}
            </b-badge>
          </p>
        </b-col>
      </b-row>
      <!--This part of code was deprecated, keep the code for reference only.-->
      <!-- <b-row v-if="troubleshootMsgs.hints && troubleshootMsgs.hints.length > 0">
        <b-col>
          <b>Troubleshooting Hint(s):</b>
        </b-col>
      </b-row>

      <b-row v-for="(hint, index) in troubleshootMsgs.hints" :key="`ts-${index}`">
        <b-col>
          {{ hint.msg }}
          <span :title="hint.dst" v-b-tooltip.hover.v-dark>
            <b-button
              variant="flat-primary"
              v-if="hint.dst"
              size="sm"
              @click="focusTabWithAlert(hint.dst)"
              class="p-0"
            >
              Details...
            </b-button>
          </span>
        </b-col>
      </b-row> -->
      <!-- <b-row v-if="troubleshootMsgs.nextSteps.length > 0">
        <b-col>
          <b>Next Steps:</b>
        </b-col>
      </b-row>
      <b-row v-for="(nextStep, index) in troubleshootMsgs.nextSteps" :key="`ns-${index}`">
        <b-col>
          {{ nextStep.msg }}
          <span :title="nextStep.dst" v-b-tooltip.hover.v-dark>
            <b-button
              variant="flat-primary"
              size="sm"
              v-if="nextStep.dst"
              @click="focusTabWithAlert(nextStep.dst)"
              class="p-0"
            >
              Details...
            </b-button>
          </span>
        </b-col>
      </b-row> -->
    </b-card>
    <b-card v-else-if="loaded" class="trouble-shooting-box">
      <b-row>
        <b-col>
          <span>No Details Available For This Alert</span>
        </b-col>
      </b-row>
    </b-card>
    <SpinnerCmpt v-else></SpinnerCmpt>
  </b-row>
</template>

<script>
import moment from 'moment';

import IssueTimeBarEventBus from '@/xvisor/bus/IssueTimeBarEventBus';
import momentTimeFormat from '@/xvisor/constants/momentTimeFormat';
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
// import hintToMsg from '@/xvisor/components/issue/hintToMsg';

export default {
  props: {
    alert: {
      type: Object,
      required: true,
    },
  },
  components: {
    SpinnerCmpt,
  },
  data() {
    return {
      // troubleshoot: undefined,
      hintsInfo: undefined,
      loaded: false,
    };
  },
  computed: {
    hasData() {
      return this.hintsInfo;
    },
    // troubleshootMsgs() {
    //   if (!this.troubleshoot) return undefined;
    //   return {
    //     alertId: this.troubleshoot.alertId,
    //     hints: this.troubleshoot.hints
    //       ? this.troubleshoot.hints.map((hint) => ({
    //         msg: hintToMsg(hint.hint, new Date()),
    //         dst: hint.dst,
    //       }))
    //       : null,
    //     nextSteps: this.troubleshoot.nextSteps,
    //   };
    // },
    hintsUrl() {
      return this.alert ? `/alerts/${this.alert.id}/hints/list.json` : undefined;
    },
  },
  mounted() {
    if (this.alert) this.httpGet();
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.hintsUrl)
        .then((response) => { this.hintsInfo = response.data; })
        .catch(() => { this.hintsInfo = undefined; })
        .finally(() => { this.loaded = true; });
    },
    focusTabWithAlert(tabName) {
      IssueTimeBarEventBus.$emit('issue-troubleshooting-focus-tab', tabName);
    },
    formatDate(dateStr) {
      return moment(dateStr).format(momentTimeFormat.dateTime);
    },
  },
  watch: {
    alert(value) {
      if (value) this.httpGet();
      else this.hintsInfo = undefined;
    },
  },
};
</script>

<style lang="scss">
.trouble-shooting-box {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  text-align: left;
  background-position: center;
  background-size: cover;
  background-color: #f8f8f8;
  color: black;
  border-radius: 0.428rem;
}
</style>
