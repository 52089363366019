<template>
  <div class="d-block">
    <div id="infra-title-section">
      <h6 class="d-inline-block" id="infra-title">{{ name }}</h6>
      <b-icon icon="info-circle" class="h3" variant="dark" id="info"></b-icon>
    </div>
    <b-popover
      target="info"
      triggers="hover"
      placement="bottom"
      class="large-width"
    >
      <div v-if="hasData">
        <div id="infra-title-inside-icon">
          <h6 class="d-inline-block"> Infra Name: </h6>
          <p>{{ name }}</p>
        </div>
        <div v-if="info && info.isActive">
          <h6 class="d-inline-block">Status:</h6>
          <b-badge class="ml-1" pill v-if="info.isActive" variant="success">Active</b-badge>
          <b-badge class="ml-1" pill v-else variant="danger">Inactive</b-badge>
        </div>
        <div v-if="info && info.ip">
          <h6 class="d-inline-block">IP: </h6>
          <span>{{ info.ip }}</span>
        </div>
        <div v-if="info && info.vendor">
          <h6 class="d-inline-block">Vendor: </h6>
          <p>{{ info.vendor }}</p>
        </div>
        <div v-if="info && info.model">
          <h6 class="d-inline-block" v-b-tooltip.hover :title="info.model">Model: </h6>
          <p>{{ collapseText(info.model, textWidth) }}</p>
        </div>
      </div>
      <div v-else>
        <h6 class="d-inline-block">No Data Available</h6>
      </div>
    </b-popover>
  </div>
</template>

<script>
import collapseText from '@/xvisor/utilities/collapseText';

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    infraInfo: {
      type: Object,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      info: null,
      textWidth: 30,
    };
  },
  computed: {
    name() {
      return this.info ? this.info.name : this.infraInfo.name;
    },
    httpParams() {
      return {
        params: {
          start: this.timeRange.start.toISOString(),
          end: this.timeRange.end.toISOString(),
        },
      };
    },
    hasData() {
      return this.info;
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    httpGet() {
      this.$http
        .get(this.url, this.httpParams)
        .then((response) => { this.info = response.data; });
    },
    collapseText,
  },
};
</script>

<style lang="scss" scoped>

#infra-title-section {
  margin-top: 0.1rem;
  display: flex;
  justify-content: start;
  align-items: center;
}

#infra-title {
  margin-right: 1.1rem;
}

@media screen and (max-width: 650px) {
  #infra-title {
    display: none !important;
  }
}
@media screen and (min-width: 650px){
  #infra-title-inside-icon {
    display: none !important;
  }
}
</style>
