<template>
  <div>
    <b-button size="sm" variant="primary" v-b-modal.add-report class="text-nowrap">
      + Add Report
    </b-button>
    <AddReportModal @added="onAdd"></AddReportModal>
  </div>
</template>

<script>
import AddReportModal from '@/xvisor/components/report/AddReportModal.vue';

export default {
  components: {
    AddReportModal,
  },
  methods: {
    onAdd() {
      this.$emit('added');
    },
  },
};
</script>
