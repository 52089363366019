<template>
  <e-chart
    v-if="hasData"
    :options="chartOptions"
    autoresize
    class="apache-echarts-default-graph"
  ></e-chart>
  <div v-else-if="loaded">
    {{ $t("No Data Available") }}
  </div>
  <SpinnerCmpt v-else></SpinnerCmpt>
</template>

<script>
import moment from 'moment';

import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import colorPalette from '@/xvisor/constants/colorPalette';
import colorPaletteShade from '@/xvisor/constants/colorPaletteShade';
import granularity from '@/xvisor/utilities/granularity';
import themeStyle from '@/xvisor/utilities/themeStyle';
import timeFormat from '@/xvisor/utilities/timeFormat';

const colors = {
  'CPU Usage': colorPaletteShade.blue4,
  'Memory Usage': colorPaletteShade.purple4,
  'WiFi Signal': colorPaletteShade.teal4,
  Baseline: colorPaletteShade.green3,
};

export default {
  components: {
    SpinnerCmpt,
  },
  props: {
    timeRange: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    eventUrl: {
      type: String,
      required: true,
    },
    metric: {
      type: String,
      required: true,
      validator: (value) => ['cpu', 'memory', 'wifi'].includes(value),
    },
  },
  data() {
    return {
      series: [],
      eventSeries: [],
      loaded: false,
    };
  },
  watch: {
    url() {
      this.httpGet();
    },
    timeRange() {
      this.httpGet();
    },
  },
  mounted() {
    this.httpGet();
  },
  computed: {
    timeSet() {
      return new Set(this.series.map((ts) => ts.points.map((point) => point.time)).flat());
    },
    hasData() {
      return this.loaded && this.series && this.series.length > 0 && this.series[0].points.length > 0;
    },
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
    toggleTooltipColor() {
      return themeStyle.styleToggle(colorPalette.white, colorPalette.tooltipBackground);
    },
    initialMarkline() {
      // Initializes the line to the end of the graph.
      const times = [...this.timeSet].map((time) => moment(time).valueOf());
      const seriesData = this.series
        .filter((ts) => ts.points.length !== 0)
        .map((ts) => ({
          data: times.map((time) => {
            const timePoint = ts.points.find((point) => moment(point.time).valueOf() === time);
            return [time, timePoint ? timePoint.value : 0];
          }),
        }));
      return seriesData[0].data.at(-1)[0];
    },
    timeSeriesFormat() {
      return timeFormat.momentFormat(this.timeSet, new Date());
    },
    formattedSeries() {
      const times = [...this.timeSet].map((time) => moment(time).valueOf());
      return this.series
        .filter((ts) => ts.points.length !== 0)
        .map((ts) => ({
          name: ts.name,
          type: 'line',
          showSymbol: false,
          itemStyle: {
            color: colors[ts.name],
          },
          lineStyle: {
            type: ts.name.includes('Baseline') ? 'dashed' : 'solid',
          },
          markLine: {
            symbol: 'circle',
            lineStyle: {
              type: 'solid',
            },
            data: [{ xAxis: this.initialMarkline }],
            label: {
              formatter: (info) => moment(info.value).format(this.timeSeriesFormat),
            },
          },
          markPoint: {
            symbol: 'triangle',
            symbolSize: 15,
            label: {
              offset: [0, 5],
              fontSize: 10,
            },
            itemStyle: {
              color: 'red',
            },
            tooltip: {
              trigger: 'item',
              formatter: (info) => {
                const cell = info.data;
                const header = `
                  <div class="apache-echarts-tooltip">
                    <div>${info.marker}${moment(cell.xAxis).format(this.timeSeriesFormat)}</div>
                `;
                const eventsRow = `<div># Events: ${cell.value > 0 ? cell.value.toFixed(0) : 0}</div>`;
                let eventMsgs = '';
                eventMsgs = `
                    <div> ${cell.name}: ${cell.msg}.</div>
                  `;
                return `${[header, eventsRow, ...eventMsgs].join('')} </div>`;
              },
            },
            data: this.eventSeries ? this.markPointHelper : [],
          },
          data: times.map((time) => {
            const timePoint = ts.points.find((point) => moment(point.time).valueOf() === time);
            return [time, timePoint ? timePoint.value : 0];
          }),
        }));
    },
    markPointHelper() {
      return this.eventSeries.map((event) => ({
        xAxis: event.time,
        yAxis: event.value,
        // There will always be 1 event for each marker.
        value: 1,
        baseline: event.baseline,
        msg: event.msg,
        type: event.type,
        name: event.name,
      }));
    },
    chartTitle() {
      if (this.metric === 'cpu') return 'CPU Usage';
      if (this.metric === 'memory') return 'Memory Usage';
      if (this.metric === 'wifi') return 'WiFi Signal Strength';
      return '';
    },
    chartUnit() {
      if (this.metric === 'cpu') return '%';
      if (this.metric === 'memory') return '%';
      if (this.metric === 'wifi') return 'dBm';
      return '';
    },
    chartOptions() {
      return {
        tooltip: {
          trigger: 'axis',
          formatter: (info) => `
            <div class="apache-echarts-tooltip">
              ${this.chartTitle} / ${moment(info[0].value[0]).format(this.timeSeriesFormat)}
              <br>
              ${this.chartTooltipContent(info)}
            </div>
          `,
        },
        grid: [
          {
            left: 50,
            right: 50,
            top: 30,
            bottom: 30,
          },
        ],
        xAxis: {
          type: 'time',
          boundaryGap: false,
          splitLine: {
            show: false,
          },
          axisTick: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLine: {
            lineStyle: {
              color: this.toggleColor,
            },
          },
          axisLabel: {
            formatter: (value) => moment(value).format(this.timeSeriesFormat),
            fontSize: 9,
          },
        },
        yAxis: [
          {
            type: 'value',
            name: this.chartUnit,
            nameLocation: 'center',
            nameGap: 35,
            axisTick: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLine: {
              lineStyle: {
                color: this.toggleColor,
              },
            },
            axisLabel: {
              formatter: (value) => value.toFixed(1),
              fontSize: 9,
            },
          },
        ],
        series: this.formattedSeries,
      };
    },
  },
  methods: {
    httpGet() {
      this.loaded = false;
      const seriesRequest = this.$http.get(this.url, {
        params: {
          start: this.timeRange.start.toISOString(),
          end: this.timeRange.end.toISOString(),
          stepMinutes: granularity.granularity72(this.timeRange),
        },
      });
      const eventSeriesRequest = this.$http.get(this.eventUrl, {
        params: {
          start: this.timeRange.start.toISOString(),
          end: this.timeRange.end.toISOString(),
        },
      });
      eventSeriesRequest
        .then((eventRes) => {
          this.eventSeries = eventRes.data;
        })
        .catch(() => {
          this.eventSeries = [];
        });
      seriesRequest
        .then((tsRes) => {
          this.series = tsRes.data;
        })
        .finally(() => {
          this.loaded = true;
        });
    },
    calculateUnit(eventName) {
      if (eventName === 'Xtend Cpu Usage') return '%';
      return '';
    },
    chartTooltipContent(info) {
      let result = '';
      for (let i = 0; i < info.length; i += 1) {
        result += `${info[i].marker} ${info[i].seriesName}: ${info[i].value[1].toFixed(1)} ${this.chartUnit} <br>`;
      }
      return result;
    },
  },
};
</script>
