<template>
  <div>
    <!-- Download Section -->
    <span id="credentials" class="anchor"></span>
    <h1>Download</h1>
    <p>
      Download xTend and Get Xtend Credential:
      <DownloadXtend></DownloadXtend>
    </p>

    <!-- Credentials Section -->
    <span id="credentials" class="anchor"></span>
    <h1>Credentials</h1>

    <h2>1. Log in to your xVisor Dashboard</h2>
    <p>Begin by accessing your xVisor Dashboard with your credentials.</p>

    <h2>2. Navigate to the Settings Page</h2>
    <p>From the dashboard, locate and click on the Settings option.</p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/xtend/xtend-settings.png"
        class="responsive-img-sm"
        alt="Settings Page"
      />
    </p>

    <h2>3. Select the “API Keys” Tab</h2>
    <p>On the Settings page, find the sidebar navigation and click on the API Keys.</p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/xtend/xtend-apikey.png"
        class="responsive-img-sm"
        alt="API Keys Tab"
      />
    </p>

    <h2>4. Go to the “xTend Registration” Tab</h2>
    <p>While on the API Keys page, look for another tab labeled xTend Registration and click on it.</p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/xtend/xtend-registration.png"
        class="responsive-img-sm"
        alt="xTend Registration Tab"
      />
    </p>

    <h2>5. Generate Your Registration Key</h2>
    <p>
      Finally, click on the Registration Key button. This will generate the credential required to register
      your xTend device. Note that this credential is valid for one week and can be used on multiple xTend devices.
    </p>
    <p>
      <img
        src="@/xvisor/views/knowledgeBase/assets/xtend/xtend-apikey-copy.png"
        class="responsive-img-sm"
        alt="Generate Registration Key"
      />
    </p>

    <!-- PowerShell Section -->
    <span id="powerShell" class="anchor"></span>
    <h1>PowerShell</h1>

    <h2>1. Download the Installer (if not yet downloaded)</h2>
    <p>
      If the installer file is not already on your computer, you can download it from the web. Navigate to the desired
      folder where you want to download the installer, then run the following command in PowerShell:
    </p>
    <pre>
      <code>
        Invoke-WebRequest –Uri https://xvisor.blob.core.windows.net/public/xtend/enterprise/windows/xtend.msi
        -OutFile xtend.msi
      </code>
    </pre>

    <h2>2. Install xTend</h2>
    <p>
      Once the installer file is downloaded, navigate to the same folder where the installer is located, and run the
      following command in PowerShell to install xTend:
    </p>
    <pre><code>./xtend.msi /qn</code></pre>

    <h2>3. Register xTend to xVisor</h2>
    <p>
      After installation, navigate to the folder where xTend was installed. By default, it will be located in
      Program Files/xTend. If you installed it to the default path, you can use this command in PowerShell:
    </p>
    <pre><code>cd 'C:\Program Files\xTend\'</code></pre>
    <p>
      Copy your credential, as it will be needed for the next step. To register xTend, run the following command in
      PowerShell, replacing <code>&lt;credential&gt;</code> with your actual credential:
    </p>
    <pre><code>.\xTend.exe &lt;credential&gt;</code></pre>

    <h2>4. Start xTend Service</h2>
    <p>
      Now that everything is set up, you can start the xTend service by running this command in PowerShell:
    </p>
    <pre><code>sc.exe start xTend</code></pre>
  </div>
</template>

<script>
import DownloadXtend from '@/xvisor/components/xtend/DownloadXtend.vue';

export default {
  components: {
    DownloadXtend,
  },
};
</script>

<style scoped>
.anchor {
  top: -75px;
  position: relative;
  z-index: 5;
}
</style>
