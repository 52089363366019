<template>
  <e-chart
    v-if="hasData"
    :options="chartOptions"
    autoresize
    @click="handleClickEvent"
    :style="{height: this.chartHeight}"
  />
  <div v-else-if="loaded" class="ml-1 mb-1">{{ $t("No Data Available") }}</div>
  <SpinnerCmpt v-else></SpinnerCmpt>
</template>

<script>
import InfrastructureEventBus from '@/xvisor/bus/InfrastructureEventBus';
import SpinnerCmpt from '@/xvisor/components/SpinnerCmpt.vue';
import colorPalette from '@/xvisor/constants/colorPalette';
import themeStyle from '@/xvisor/utilities/themeStyle';

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    timeRange: {
      type: Object,
      required: true,
    },
  },
  components: {
    SpinnerCmpt,
  },
  watch: {
    timeRange() {
      this.httpGet();
    },
  },
  data() {
    return {
      series: [],
      loaded: false,
      rowLength: 15,
    };
  },
  computed: {
    hasData() {
      return this.loaded && this.series && this.series.length > 0;
    },
    // Sets the chart height so when data only uses up one row the cells look like squares.
    chartHeight() {
      if (this.series.length <= this.rowLength) {
        return '100px !important';
      }
      return '140px !important';
    },
    formattedUtilizationSeries() {
      return this.series.map((element) => (
        { ...element, utilization: element.utilization * 100 }
      ));
    },
    // Formats the heatmap to have the correct number of cells if it's less than row length.
    seriesFormat() {
      const paddedSeries = [...this.formattedUtilizationSeries];
      if (paddedSeries.length < this.rowLength) {
        for (let i = this.series.length; i < this.rowLength; i += 1) {
          paddedSeries.push({});
        }
      }
      return paddedSeries.map((element, index) => {
        const x = Math.floor(index / this.rowLength);
        const y = index % this.rowLength;
        return [y, x, element];
      });
    },
    heatmapData() {
      return this.seriesFormat
        .map((item) => ({
          value: [item[0], item[1], item[2].utilization],
          name: item[2].name,
          snmpIndex: item[2].snmpIndex,
        }));
    },
    toggleColor() {
      return themeStyle.styleToggle(colorPalette.black, colorPalette.white);
    },
    chartOptions() {
      return {
        tooltip: {
          position: 'top',
          formatter: (params) => `
            <div class="apache-echarts-tooltip">
              <div>Name: ${params.data.name}</div>
              <div>${params.marker} Utilization: ${params.data.value[2].toFixed(1)}%</div>
            </div>
          `,
        },
        grid: {
          left: 40,
          top: 30,
          right: 40,
          bottom: 30,
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitArea: {
            show: true,
          },
        },
        yAxis: {
          type: 'category',
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitArea: {
            show: true,
          },
        },
        visualMap: {
          precision: 1,
          min: 0,
          max: 100,
          show: false,
        },
        series: [
          {
            name: 'Data',
            type: 'heatmap',
            data: this.heatmapData,
            itemStyle: {
              borderColor: colorPalette.white,
              borderWidth: 2,
            },
          },
        ],
      };
    },
  },
  mounted() {
    this.httpGet();
  },
  methods: {
    httpGet() {
      this.loaded = false;
      this.$http
        .get(this.url, {
          params: {
            start: this.timeRange.start.toISOString(),
            end: this.timeRange.end.toISOString(),
          },
        })
        .then((response) => { this.series = response.data; })
        .finally(() => { this.loaded = true; });
    },
    handleClickEvent(event) {
      InfrastructureEventBus.$emit('heatmap-interface-click', event.data);
    },
  },
};
</script>

<style>
.echarts {
  width: 100% !important;
}
</style>
