<template>
  <div>
    <b-card
      no-body
      class="knowledge-base-bg text-center undo-color-overlay change-padding"
      :style="{backgroundImage: `url(${require('@/assets/images/banner/banner.png')})`}"
    >
      <b-card-body class="card-body">
        <h2 class="text-primary">xVisor {{ $t('Knowledge Base') }}</h2>

        <b-row align-h="center" align-v="center">
          <b-col lg="5" md="10" sm="12">
            <SearchBar size="md">
              <template #input>
                <b-form-input v-model="searchQuery" type="search" placeholder="Search" />
              </template>
            </SearchBar>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <div id="knowledge-base-category">
      <div id="knowledge-base-row">
        <div
          v-for="(item, index) in filteredKB"
          :key="index"
          class="kb-search-content col-md-15"
        >
          <b-card class="text-center cursor-pointer knowledge-base-panel">
            <h4 @click="$router.push({ path: item.route })">
              <b-icon :icon="item.icon" :class="item.iconClass" class="category-margin" />
              {{ item.title }}
            </h4>
            <vue-perfect-scrollbar :settings="scrollSettings" class="scroll-style">
              <ul
                class="mt-2 ml-n2 cursor-pointer"
                align="left"
                @click="$router.push({ path: item.route })"
              >
                <li
                  style="text-align: left;"
                  class="list-item-margin"
                  v-for="(topic, index) in item.categoryOptions"
                  :key="index"
                >
                  {{ topic }}
                </li>
              </ul>
            </vue-perfect-scrollbar>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

import SearchBar from '@/xvisor/components/SearchBar.vue';

export default {
  components: {
    SearchBar,
    VuePerfectScrollbar,
  },
  data() {
    return {
      searchQuery: '',
      scrollSettings: {
        maxScrollbarLength: 50,
      },
      docs: [
        {
          title: 'Introduction to xVisor',
          icon: 'house-door',
          iconClass: 'text-primary',
          route: '/kb/intro-xvisor',
          categoryOptions: [
            'Ennetix xVisor Overview',
            'XOME Deployment and Data Collection',
            'Ennetix xVisor Signup and Installation Process',
          ],
        },
        {
          title: 'xVisor Web Portal',
          icon: 'bezier',
          iconClass: 'text-secondary',
          route: '/kb/web-portal',
          categoryOptions: [
            'Sign Up and Log In',
            'Overview Page',
            'Applications Summary Page',
            'Application Details Page',
            'Network Elements Page',
            'Individual Network Element Page',
            'xTends Page',
            'Individual xTend Page',
            'Reports Page',
            'Settings Page',
            'User Profile Page',
            'Status Page',
          ],
        },
        {
          title: 'About Ennetix',
          icon: 'clock-history',
          iconClass: 'text-success',
          route: '/kb/about-ennetix',
          categoryOptions: [
            'Who are we?',
          ],
        },
        {
          title: 'XOME Installation',
          icon: 'cloud-check',
          iconClass: 'text-dark',
          route: '/kb/xome-installation',
          categoryOptions: [
            'XOME Installation',
            'XOME Registration',
          ],
        },
        {
          title: 'XOME Configuration',
          icon: 'clipboard-check',
          iconClass: 'text-info',
          route: '/kb/xome-configuration',
          categoryOptions: [
            'Configuring Probe Data Source',
            'Configuring Netflow Data Source',
            'Configuring SFlow Data Source',
            'Configuring Wire Data Capture Source',
            'Configuring SNMP Data Source',
            'Configuring Syslog Data Source',
          ],
        },
        {
          title: 'FAQ',
          icon: 'question-circle',
          iconClass: 'text-danger',
          route: '/kb/faq',
          categoryOptions: [
            'What is Ennetix xVisor?',
            'How does xVisor work?',
            'What will xVisor do for me?',
            'What additional insights does xVisor provide?',
            'How does xVisor compare to other products?',
            'How long does it take to deploy xVisor?',
            'How soon can I expect xVisor benefits to start?',
            'Where will my data be stored?',
          ],
        },
        {
          title: 'xTend Guide',
          icon: 'card-checklist',
          iconClass: 'text-primary',
          route: '/kb/xtend-guide',
          categoryOptions: [
            'Download',
            'Credentials',
            'PowerShell',
          ],
        },
        {
          title: 'Security Dashboard',
          icon: 'shield-lock',
          iconClass: 'text-primary',
          route: '/kb/security-guide',
          categoryOptions: [
            'Service Tabs',
            'Selecting Addresses of Interest',
            'Moving Averages',
            'ASN and location information for an address',
            'DNS fields',
            'SSH, HTTP, and HTTPS fields',
            'Charts for selected addresses',
            'Threat Hunting',
            'Endpoint Analytics',
          ],
        },
      ],
    };
  },
  computed: {
    filteredKB() {
      const searchQueryLower = this.searchQuery.toLowerCase();
      return this.docs.filter((item) => item.title.toLowerCase().includes(searchQueryLower)
        || item.categoryOptions.filter((option) => option.toLowerCase().includes(searchQueryLower)).length > 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-knowledge-base.scss';
#knowledge-base-row {
  display: flex;
}
.knowledge-base-panel {
  margin-top: -1.5rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;;
}

.col-md-15 {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

@media (max-width: 991px) {
  #knowledge-base-category {
    margin-top: 3rem;
  }
  #knowledge-base-row {
    flex-flow: column;
  }
  .col-md-15 {
    width: 100%;
    float: left;
  }
}
@media (min-width: 992px) {
  #knowledge-base-category {
    margin-top: 3rem;
  }
  .col-md-15 {
      width: 33.3%;
      float: left;
  }
  #knowledge-base-row {
    flex-wrap: wrap;
  }
}
@media (min-width: 1200px) {
  #knowledge-base-category {
    margin-top: 3rem;
  }
  .col-md-15 {
      width: 33.3%;
      float: left;
  }
  #knowledge-base-row {
    flex-wrap: wrap;
  }
}

#knowledge-base-category {
  margin-left: -1.75rem;
  margin-right: -1.75rem;
}
.undo-color-overlay {
  background-color: rgba(white, 0.05) !important;
}
.knowledge-base-bg {
  margin: -1.0rem -1.5rem;
  .card-body {
    padding: 4rem !important;
  }
}
.category-margin {
  margin-right: 5px;
}
.list-item-margin {
  margin-bottom: 5px;
}
</style>
